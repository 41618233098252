// Handle lazy loading of images...
if (!!window.IntersectionObserver) {
  var observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        if (!entry.target.dataset.src) {
          observer.unobserve(entry.target);
          return;
        }

        if (entry.target.dataset.srcset) {
          entry.target.srcset = entry.target.dataset.srcset;
        }

        entry.target.src = entry.target.dataset.src;
        observer.unobserve(entry.target);
      }
    });
  });
  document.querySelectorAll('img').forEach(function (img) { observer.observe(img) });
}

// MapMarker Icon
var icon = '/media/images/map_marker@2x.png';

$(function () {
  $('a[href*="~wizard"]').attr('href', '#').addClass('open-wizard');
  if ($('button.btn-external').length > 0) {
    $.each($('button.btn-external'), function () {
      var durl = $(this).data('url');
      if (durl == '~wizard') {
        $(this).addClass('open-wizard');
      } else {
        $(this).on('click', function (e) {
          e.preventDefault();
          window.open(durl);
        });
      }
    });
  }
});
$(function () {
  achorScroll();
});
// $(function() {
// 	$(".enable-select2").select2();
// });
$(function () {
  $(".ga-check").on('click', function () {
    ga('send', 'event', 'Lead Capture', $(this).data('action'), $(this).data('label'));
  });
});

$(function () {
  if (typeof form_result === "undefined" || form_result == false) return;
  setTimeout(function () {
    var form = $(form_result).parents('.container');
    scrollTo(form);
  }, 1000);
});

// BOF CheckMyFile modal...
var trigger_modal = $('.contact_form').data('modal-trigger');
var modal_delay = $('.contact_form').data('modal-delay');
if (trigger_modal) {

  $(trigger_modal).modal('show');

  if (modal_delay) {
    setTimeout(function () {
      $(trigger_modal).modal('show');
    }, modal_delay);
  } else {
    $(trigger_modal).modal('show');
  }

}
// EOF CheckMyFile modal...

// BOF Live Chat Button
$(document).ready(function () {
  $('#syndeo-web-chat-button').fadeIn('fast');
})
// EOF Live Chat Button

// BOF Header V2
var closeTimeout;

$('.header-v2-menu-item').on('mouseenter', function () {
  // $(this).addClass('selected-menu');
  if ($(this).data('dropdown')) {
    var dropdown = '#' + $(this).data('dropdown');
    $(dropdown).addClass('show');

    // Cancel the close timeout when the mouse enters the dropdown
    $(dropdown).on('mouseenter', function () {
      clearTimeout(closeTimeout);
    });
  }
}).on('mouseleave', function () {
  var dropdown = '#' + $(this).data('dropdown');

  // Set a small delay before closing the dropdown
  var that = this;
  closeTimeout = setTimeout(function () {
    $(dropdown).removeClass('show');
    // $(that).removeClass('selected-menu');
  }, 0);
});

$('.header-v2-dropdown').on('mouseleave', function () {
  var dropdown = '#' + $(this).attr('id');
  if (window.matchMedia("(min-width: 991px)").matches) {
    setTimeout(function() {
      $(dropdown).removeClass('show');
    }, 200);
  }
});

// $('.toggle-sticky-header').on('click', function () {
//   $('#header.header-v2').toggleClass('non-sticky');
// });

$(function () {
  var menuOpen = false;
  $('#header-v2-burger').click(function () {
    menuOpen = !menuOpen;
    if (menuOpen) {
      $('.menu-icon').hide();
      $('.close-icon').show();
      // show the menu
    } else {
      $('.menu-icon').show();
      $('.close-icon').hide();
      // hide the menu
    }
  });
});

$('#header-v2-burger').on('click', function () {
  $(this).toggleClass('burger-open');
  $('#header-v2-mobile-menu').toggleClass('show');
  $('#sticky-cta').removeClass('sticky-cta-active');
  // $('body').toggleClass('no-scroll');
});

$('#header-v2-mobile-menu .header-v2-dropdown h3').on('click', function () {
  // Close all other dropdowns
  $('#header-v2-mobile-menu .header-v2-dropdown h3').not(this).parent().next().slideUp();
  $(this).parent().next().slideToggle();
});

// var dropdown = '#' + $(this).data('dropdown');

// Close the dropdown when the mouse leaves both .header-v2-menu-item and the dropdown
var dropdown = '#' + $(this).data('dropdown');
$('.header-v2-menu-item').add(dropdown).on('mouseleave', function () {
  $(dropdown).removeClass('show');
  // $(this).removeClass('selected-menu');
});
// EOF Header V2

// BOF Sticky Disclaimer...
$(document).scroll(function () {

  // console.log('Scrolling');

  var sh = $(document).scrollTop();
  var disclaimer_cookie = window.localStorage.getItem('mortage_disclaimer');

  if (sh > 1000 && disclaimer_cookie == null) {
    $('#disclaimer-notice').addClass('show');

    setTimeout(function () {
      $('#disclaimer-notice').removeClass('show');
      window.localStorage.setItem('mortage_disclaimer', JSON.stringify({ "mortage_disclaimer": 1 }));
    }, 10000);
    clearTimeout();
  }
})

$('#disclaimer-notice button').click(function () {
  $('#disclaimer-notice').removeClass('show');
  window.localStorage.setItem('mortage_disclaimer', JSON.stringify({ "mortage_disclaimer": 1 }));
})
// EOF Sticky Disclaimer...

// BOF Sticky CTA...

$(document).scroll(function () {
  var window_height = $(window).height();
  var sh = $(document).scrollTop();

  // console.log(window_height, sh);

  // If #header-v2-mobile-menu .show is present, don't show the sticky CTA
  if ($('#header-v2-mobile-menu').hasClass('show')) {
    return;
  }

  if (sh > 70) {
    $('#sticky-cta').addClass('sticky-cta-active');
  } else {
    $('#sticky-cta').removeClass('sticky-cta-active');
  }
})

$(function () {
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="popover-share"]').popover();
})

$(document).on('click', function (e) {
  $('[data-toggle="popover-share"],[data-original-title]').each(function () {
    if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
      if ($(this).popover('hide').data('bs.popover').inState.click) {
        $(this).removeClass('active');
      }
      (($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false  // fix for BS 3.3.6
    }

  });
});

$(function () {
  if (typeof (flush_cart) !== 'undefined' && flush_cart) {
    setTimeout(function () {
      DefaultAdapter.emptyCart();
    }, 1);
  }



  initJumpNav();
  $('.video').click(function (e) {
    e.preventDefault();
    $(this).addClass('active');
  });

  $(document).on('click', '.load_more_posts', function (e) {
    e.preventDefault();
    var $$ = $(this);
    var next_page = $(this).data('next');
    var page_id = $(this).data('page_id');
    var category = $(this).data('category');

    if (typeof (page_id) == 'undefined' || page_id == '') page_id = 2;



    $.ajax(next_page + '?page=' + page_id + '&category=' + category)
      .done(function (data) {
        $$.data('page_id', page_id + 1);
        var posts = $(data.items).find('.post_wrap_grid');

        $.each(posts, function (i, itm) {
          var image_src = $(itm).find('div.cover > img');
          image_src.attr('src', image_src.data('src'));
        });

        $('.posts-backgound').append(posts);
        if (!data.more_pages) {
          $$.hide();
        }
      });

  });

  initImage();

  $('#cookie_notice').click(function (e) {
    e.preventDefault();
    $(".banner-cookie").removeClass('actives');
    $(".mobile_cookie").removeClass('banner-active');
    $(".banner-cookie p").remove();
    $(".banner-cookie h3").remove();
    $(".spacer").removeClass('active-sp');
    Cookie.storeData();


  });

  Cookie.init();
  initImageReel();
});




function initImageReel() {
  $('.image_reel').each(function (i, itm) {
    calcReel($(this));
  });

  $(window).resize(function () {
    $('.image_reel').each(function (i, itm) {
      calcReel($(this));
    });
  });
}

function calcReel($el) {
  var div_width = $el.find('.breakout').width();

  var animation_id = $el.data('animation_id');
  if (!animation_id) {
    animation_id = 'reel_' + cuniq();
  }
  $el.data('animation_id', animation_id);

  var $img = $('.breakout img', $el);
  var duration = $img.data('duration');

  // Create a new <img>
  var img_width = $img.width();
  var max_move = img_width - div_width;



  $.keyframe.define([{
    name: animation_id,
    '100%': { transform: "translateX(-" + max_move + "px)" }
  }]);

  $img.playKeyframe({
    name: animation_id, // name of the keyframe you want to bind to the selected element
    duration: duration + 's', // [optional, default: 0, in ms] how long you want it to last in milliseconds
    timingFunction: 'ease-in-out', // [optional, default: ease] specifies the speed curve of the animation
    delay: '0s', //[optional, default: 0s]  how long you want to wait before the animation starts
    iterationCount: 'infinite', //[optional, default:1]  how many times you want the animation to repeat
    direction: 'alternate', //[optional, default: 'normal']  which direction you want the frames to flow
    fillMode: 'forwards', //[optional, default: 'forward']  how to apply the styles outside the animation time, default value is forwards
  });

}

var c = 1;
function cuniq() {
  var d = new Date(),
    m = d.getMilliseconds() + "",
    u = ++d + m + (++c === 10000 ? (c = 1) : c);
  return u;
}

function initImage() {
  $('.product_product_image>div').click(function (e) {
    e.preventDefault();

    var src = $(this).find('img').attr('src');
    var src_set = $(this).find('img').attr('src-set');

    $('.image_holder img').attr('src', src);
    $('.image_holder img').attr('src-set', src_set);

    var img_parent = $('.image_holder').find('img').parent();
    img_parent.attr('href', src);
    img_parent.data('lightbox', $('.product_product_image').index($(this)));

    $('.product_product_image>div.active').removeClass('active');
    $(this).addClass('active');

    return false;
  });
}

function initJumpNav() {
  var jump_links = $('.jump_nav_link');

  jump_links.each(function (itm) {
    var direction = $(this).data('direction');

    if (direction == 'down') {
      if (jump_links.index(this) < jump_links.length - 1) {
        $(this).show();
      }
    } else if (direction == 'up') {
      if (jump_links.index(this) > 0) {
        $(this).show();
      }
    }
  });

  $(document).on('touchstart click', '.jump_nav_link', function (e) {
    e.preventDefault();
    var direction = $(this).data('direction');
    var idx = jump_links.index(this);

    if (direction == 'down') {
      scrollTo(jump_links.eq(idx + 1).parents('.jump_nav_group'));
    } else if (direction == 'up') {

      if ((idx - 1) == 1) {
        scrollTo(null);
      }
      else {
        scrollTo(jump_links.eq(idx - 1).parents('.jump_nav_group'));
      }
    }
  });
}

function scrollTo($el) {
  if ($el == null) {

    $('html, body').animate({
      scrollTop: 0
    }, 750);
    return;
  }
  var spacer = $('.spacer').outerHeight();

  $('html, body').animate({
    scrollTop: $el.offset().top - spacer
  }, 350);
}

$("a[href*='#contact_form']").click(function (e) {
  e.preventDefault();
  var form = $('#contact_form').parents('.container');

  scrollTo(form);
});

var map;

function initialize() {
  if ($('#map').length == 0) return;

  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 9,
    center: new google.maps.LatLng(latitude, longitude),
    mapTypeId: 'roadmap',
    animation: google.maps.Animation.DROP,
    scrollwheel: false,
    styles: [{ "featureType": "all", "elementType": "labels.text.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [{ "color": "#000000" }, { "lightness": 13 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#000000" }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#144b53" }, { "lightness": 14 }, { "weight": 1.4 }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#08304b" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#0c4152" }, { "lightness": 5 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#000000" }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#0b434f" }, { "lightness": 25 }] }, { "featureType": "road.arterial", "elementType": "geometry.fill", "stylers": [{ "color": "#000000" }] }, { "featureType": "road.arterial", "elementType": "geometry.stroke", "stylers": [{ "color": "#0b3d51" }, { "lightness": 16 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#000000" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "color": "#146474" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#021019" }] }]
  });

  addMarker({
    position: new google.maps.LatLng(latitude, longitude),
    type: 'info',
    animation: google.maps.Animation.DROP,
  })

}

function addMarker(feature) {
  var marker = new google.maps.Marker({
    position: feature.position,
    animation: google.maps.Animation.DROP,
    icon: new google.maps.MarkerImage(icon, null, null, null, new google.maps.Size(36, 52)),
    map: map
  });
}

$('.contact_page input[name="phone"]').on('input', function () {
  var phone = $(this).val().replace(/\s+/g, '')
  $(this).val(phone);
});

/* 
* IMPORTANT: This is a temporary fix for the cookie notice
*/

var bannerTimeOut = setTimeout(function () {
  $(".banner-cookie").addClass('actives');
  $(".mobile_menu").addClass('banner-active');
  $(".spacer").addClass('active-sp');
}, 15000);
var Cookie = {

  init: function () {
    if (typeof (Storage) === "undefined") {
      console.error('Storage is required for cookie notice to work');
    }
    this.loadData();

  },

  loadData: function () {
    var data = JSON.parse(localStorage.getItem('cookie_notice', data));
    if (data) {
      Cookie.updateCart(data);
    } else {
      Cookie.storeData();
    }
  },
  updateCart: function (cookie_notice) {
    if (cookie_notice.cookie == 1) {
      clearTimeout(bannerTimeOut);
      $(".banner-menu").removeClass('actives');
      $(".mobile_menu").removeClass('banner-active');
      $(".spacer").removeClass('active-sp');
      $(".banner-cookie p").remove();
      $(".banner-cookie h3").remove();
    }
    else {
      $(".banner-menu").addClass('actives');
      $(".mobile_menu").addClass('banner-active');
      $(".spacer").addClass('active-sp');
    }
  },
  storeData: function () {
    var data = JSON.stringify({ "cookie": 1 });
    localStorage.setItem('cookie_notice', data);
  },
}

function achorScroll() {
  var id = window.location.hash;
  if (id.length == 0) return;
  var anchor = $(window.location.hash);
  scrollTo(anchor);
}

$(document).ready(function() {
  $('.dropdown-menu .dropdown-item').click(function() {
      console.log('clicked');
      var selectedText = $(this).text().toLowerCase();
      console.log(selectedText);

      $(this).parents('.dropdown').find('.dropdown-toggle').text(selectedText);
      // $('.dropdown-toggle').text(selectedText);
  });
});

$(document).ready(function() {
  $('.dropdown-menu').each(function() {
    var selectedText = $(this).find('.dropdown-item').first().text().toLowerCase();
    $(this).prev('.dropdown-toggle').text(selectedText);
  });
});

$(document).ready(function() {
  $('.go-cta').click(function() {
      var ids = ['want_to', 'help_with'];

      var selectedValues = ids.reduce(function(acc, id) {
        acc[id] = $('#' + id).text().toLowerCase().replace(/ /g, '_');
        return acc;
      }, {});

      var url = '/wizard-journey';

      url += '?';
      var keys = Object.keys(selectedValues);
      for (var i = 0; i < keys.length; i++) {
        url += keys[i] + '=' + selectedValues[keys[i]];
        if (i < keys.length - 1) {
          url += '&';
        }
      }
      window.location.href = url;
  });
});