var enquiry_form = {
	init: function() {
		if ($('.contact_form').length == 0 && $('.calculator-row').length == 0) return;
		this.checkSection();
	},
	checkSection: function() {
		var pass_section;
		$('.required').on('keyup', function (e) {
			pass_section = 0;
			$(".required").each(function (i, item) {
				if ($(item).val() != '') {
					pass_section +=  1;
				}
			})
			if(pass_section == $(".required").length) {
				enquiry_form.setSubmitButtonState(false);
			} else {
				enquiry_form.setSubmitButtonState(true);
			}
		});
	},
	setSubmitButtonState: function(state) {
		var  $$  = $('.submit-button');
		$$.prop('disabled', state);
		if (state) {
			$$.addClass('disabled');
		} else {
			$$.removeClass('disabled');
		}
	},
};
$(function () {
	enquiry_form.init();
});

$('#full_contact_form').submit(function(event) {
	event.preventDefault();
	grecaptcha.ready(function() {
		grecaptcha.execute(grecaptcha_key, {action: 'contact_form'}).then(function(token) {
			$('#full_contact_form').prepend('<input type="hidden" name="recaptcha_token" value="' + token + '">');
			$('#full_contact_form').prepend('<input type="hidden" name="action" value="contact_form">');
			$('#full_contact_form').unbind('submit').submit();
		});;
	});
});
