$(function () {
  if($('.circle1').length == 0) return;
  function setCircle() {
    var circle1 = $('.circle1');
    var circle2 = $('.circle2');
    var circle3 = $('.circle3');
    circle2.css('min-height', '100%');

    var circle2_height = circle2.height();

    circle2.width(circle2_height);
    if($(window).width() < circle2.height()) {
      circle2.width($(window).width());
      circle2.height($(window).width());
      circle2.css('max-height', $(window).width());
      circle2.css('min-height', 'unset');
    }

    var circle1_height = percentOf(circle2_height, 22);
    var circle3_height = percentOf(circle2_height, 53);
    circle1.width(circle1_height);
    circle1.height(circle1_height);

    circle3.width(circle3_height);
    circle3.height(circle3_height);


    var circleTimeout2 = setTimeout(function() {
      $('.circle-holder3').addClass('active');
      $('.circle-holder').addClass('active');
    }, 300);


  };

  function percentOf(large_circle_height, percent) {
    return large_circle_height / 100  * percent;
  };
  var circleTimeout = setTimeout(function() {
    setCircle();
  }, 300);

  $(window).resize(function() {
    setCircle();
  });
});
