const findEmailAddressInString = function(text) {
   return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
};

const maskEmailAddress = function(emailAddress, mask) {
    const pieces = emailAddress.split("@");
    const domainPieces = pieces[1].split('.');

    return pieces[0] + '@' + mask.repeat(domainPieces[0].length) + '.' + mask.repeat(domainPieces[1].length);
};

const lcm = window.LCM;

const initializeLivechat = function(lcm, lcmChat) {
    var leadAlreadyCaptured = lcmChat.getUserEmailCookie().trim().length > 0;
    lcm.setCallback("StartChat", function(email, msg, type) {
        email = email.trim();
        if(!leadAlreadyCaptured && (!email || email.length < 1)) {
            var emailAddress = findEmailAddressInString(msg);
            if(emailAddress) {
                ga('send','event', 'Lead Capture', 'Livechat', 'Email | ' + maskEmailAddress(emailAddress, '*'))
                leadAlreadyCaptured = true;
            }
        } else if(!leadAlreadyCaptured) {
            console.log('Live Chat - Start');
            ga('send','event', 'Live Chat', 'Livechat', 'No Email Entered ');
        }
    });


    lcm.setCallback("ChatMessageSent", function(msg) {
        if(!leadAlreadyCaptured) {
            var emailAddress = findEmailAddressInString(msg);

            if(emailAddress) {
                ga('send','event', 'Lead Capture', 'Livechat', 'Email | ' + maskEmailAddress(emailAddress, '*'))
                leadAlreadyCaptured = true;
            } else if (!leadAlreadyCaptured) {
                console.log('Live Chat - Sent');
                ga('send','event', 'Live Chat', 'Livechat', 'No Email Entered ');
            }
        }
    });
};
