$(function () {
	setToggleNav();
	setClickAway();
});

function setToggleNav() {
	$('.toggle_nav').click(function(e) {
		e.preventDefault();
		$('.mobile_menu').toggleClass('active');
		$('.background-cover').toggleClass('active');
		$('body').toggleClass('no_scroll');
		$('.toggle_nav').toggleClass('in');
		$(this).toggleClass('active').blur();
	});
}

function setClickAway() {
	$('.background-cover').on('click', function(e){
		e.preventDefault();
		$('.mobile_menu').toggleClass('active');
		$('body').toggleClass('no_scroll');
		$('.toggle_nav').toggleClass('in');
		$(this).toggleClass('active').blur();
	});
}




// $('.dropdown').hide();

  // $('.dropdown-toggle').click(function(e) {
  //   e.preventDefault();

  //   var $parent = $(this).parent();
  //   if($('.dropdown',$parent).is(':visible')) {
  //     $parent.removeClass('active');

  //     $('.dropdown',$parent).hide();
  //   }
  //   else {
  //     $parent.addClass('active');

  //     $('.dropdown',$parent).show();

  //     setTimeout(function() {
  //       $( document ).one( "click", function() {
  //         $('.dropdown-toggle',$parent).click();
  //       });
  //     },1);
  //   }
  // });
