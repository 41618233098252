;
$(document).on('click', '.load_more_search', function(e){
	e.preventDefault();
	var $$ = $(this);
	var next_page = $(this).data('next');
	var page_id = $(this).data('page_id');
	var category = $(this).data('category');
	var search = $(this).data('search');

	if(typeof(page_id) == 'undefined' || page_id == '') page_id =2;

	$.ajax(next_page + '?page=' + page_id +'&search=' + search +'&category=' + category)
	.done(function(data) {
		$$.data('page_id',page_id+1);
		var item_html = $(data.items).find('.item');

		$('.' + category ).find('.related_link').append(item_html);
		if(!data.more_pages) {
			$$.hide();
		}
	});
});
$(function () {
	$('.search-btn').on('click', function (e) {
		$( this).parent().submit();
	});
});
