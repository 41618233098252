var animationTimeOut = 5000;
var foundTimeOut = 2500;
var autoWizardTimeOut = 20000;
var lead_capture = false;

var remortgage_advice_item = $('.wizard-item.remortgage-advice');
var cta_mortgage_advice_item = $('.cta-wizard-btn');
var mortgage_wizard_item = $('.wizard-item.mortgage-advice');
var btl_mortgage_wizard_item = $('.wizard-item.btl_mortgage');

var WizardComponent = {
	init: function() {
		this.setOpenWizard();
		this.setShortcode();
		this.setWizard();
		this.setAutoWizard();
		this.setWizardItemBtns();
		this.setWizardBtn();
		this.reset();
	},
	setFocus: function(tag_id) {
		$(tag_id).find('.wfocus').focus();
	},
	setWizardBtn: function() {
		$('.wizard-btn').on('click', function(e) {
			if ($(this).hasClass('disabled')) return;
			$('input[name="'+ $(this).data('name') +'"]').attr('checked', false);
			$(this).find('input').prop('checked', true);
			$(this).closest('.wizard_form').submit();
		});

		$('.active-change').on('change keyup', function() {
			var $$ = $(this).closest('.wizard_form');
			var pass = true;

			$$.find('.active-change').each(function(){
				if($(this).val() == "") {
					pass = false;
				}
			});
			if($(this).val() !="") {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}

			if($(this).val() !="" && pass) {

				$$.find('.wizard-btn').removeClass('disabled').prop('disabled', false);
				$$.find('.wizard-btn .active-btn').removeClass('hide');
				$$.find('.wizard-btn .hover-btn').removeClass('hide');
				$$.find('.wizard-btn .inactive-btn').addClass('hide');
				$$.find('label').removeClass('inactive');
			} else {
				$$.find('.wizard-btn').addClass('disabled').prop('disabled', true);
				$$.find('.wizard-btn .active-btn').addClass('hide');
				$$.find('.wizard-btn .hover-btn').addClass('hide');
				$$.find('.wizard-btn .inactive-btn').removeClass('hide');
				$$.find('label').addClass('inactive');
			}
		});
	},
	setShortcode: function() {
		if ($('#wizard-shortcode').length ==0) return;
		WizardComponent.animateWizardItems($('#wizard-shortcode'));
	},
	setOpenWizard: function() {
		$('.open-wizard').on('click',function(e){
			e.preventDefault();
			WizardComponent.animateWizardItems($('#wizard_modal'));
			WizardComponent.autoActivateWizard();
			if ($(window).width() < 768) {
				scrollTo();
			}
		});
	},
	setWizard: function() {
		if($('#wizard_modal').length == 0) return;
		WizardComponent.resetWizard();
	},
	setAutoWizard: function() {
		// if (! auto_wizard) {
		// 	setTimeout(function(){
		// 		WizardComponent.animateWizardItems($('#wizard_modal'));
		// 		WizardComponent.autoActivateWizard();
		// 		WizardComponent.createCookie();
		// 	}, autoWizardTimeOut);
		// }
	},
	reset: function() {
		$('#wizard_modal').on('hidden.bs.modal', function () {
			WizardComponent.resetWizard();
		});
	},
	createCookie: function() {
		$.get('/wizard/cookie');
	},
	autoActivateWizard: function() {
		WizardComponent.toggleAnimations();
		if(!$('#wizard_modal').is(':visible')) {
			WizardComponent.showNextWizard('auto_interested_in');
		}
		$('#wizard_modal').modal('show');
		if ($(window).width() < 768) {
			scrollTo();
		}
	},
	resetWizard: function() {
		WizardComponent.removeAllHidden();
		WizardComponent.resetInterestedIn();
		WizardComponent.resetForm('type');
		WizardComponent.resetForm('reason');
		WizardComponent.resetForm('property_found');
		WizardComponent.resetForm('property_type');
		WizardComponent.resetForm('property_value');
		WizardComponent.resetForm('deposit');
		WizardComponent.resetForm('adverse_credit');
		WizardComponent.resetForm('adverse_details');
		WizardComponent.resetForm('annual_income');
		WizardComponent.resetForm('details');
		WizardComponent.resetForm('details_two');
		WizardComponent.resetForm('details_three');

		WizardComponent.resetAnimation();
		WizardComponent.ajaxSubmit();

		WizardComponent.setBackButton();
		WizardComponent.setRadio('contact');
		WizardComponent.setCheckBoxes('adverse_details');
		WizardComponent.enableRadioNext();

		WizardComponent.toggleAnimations();
		lead_capture = false;
	},
	resetAnimation: function() {
		$('#animation').find('.quote-row').removeClass('hide');
		$('#animation').find('.animation-holder ').removeClass('hide');
		$('#animation').find('.lg-row').addClass('hide');
	},
	resetCheckboxes: function(tag_id) {
		$(tag_id +' input:checkbox').removeAttr('checked');
		$(tag_id +' input:checkbox').parent('label').removeClass('active');
	},
	resetRadioBoxes: function(tag_id) {
		$(tag_id +' input:radio').removeAttr('checked');
	},
	resetInput: function(type, tag_id) {
		$(tag_id +' input[type="'+type+'"]').val('');
	},
	resetButton: function(tag_id) {
		$(tag_id +' button').addClass('disabled');
		$(tag_id +' button').find('.hover-btn').addClass('hide');
		$(tag_id +' button').find('.active-btn').addClass('hide');
		$(tag_id +' button').find('.inactive-btn').removeClass('hide');
	},
	resetForm: function(form) {
		$('#'+form).hide();
		WizardComponent.resetRadioBoxes('#'+form);
		WizardComponent.resetCheckboxes('#'+form);
		WizardComponent.resetInput('text', '#'+form);
		WizardComponent.resetInput('number', '#'+form);
		WizardComponent.resetInput('email', '#'+form);
		WizardComponent.resetInput('tel', '#'+form);
		WizardComponent.resetButton('#'+form);

	},
	resetInterestedIn: function() {
		remortgage_advice_item.removeClass('active');
		mortgage_wizard_item.removeClass('active');
		btl_mortgage_wizard_item.removeClass('active');
	},
	ajaxSubmit: function() {
		if($('#wizard_modal').data('init')) return;

		$('#wizard_modal').on('submit', 'form', function(e) {
			e.preventDefault(e);
			var $form = $(this);
			var form_id = $form.attr('id');
			var has_submit = $form.find(':submit').length > 0;

			if( has_submit && $form[0].checkValidity() || !has_submit) {
				$.post({
					url:$(this).attr('action'),
					data:$(this).serialize() +'&id=' + form_id,
					dataType: 'json',
					success: function(data){
						WizardComponent.addHidden(data.route, 'start', data.start);
						WizardComponent.addHidden(data.route, 'route_item', data.route_item);
						WizardComponent.addHidden(data.route, 'wizard_id', data.id);

						if(data.route == 'contact') {
							grecaptcha.ready(function() {
									grecaptcha.execute(grecaptcha_key, {action: 'wizard_lead'}).then(function(token) {
										WizardComponent.addHidden(data.route, 'recaptcha_token', token);
										WizardComponent.addHidden(data.route, 'action', 'wizard_lead');
									});
								});
						}

						WizardComponent.showNextWizard(data.route, form_id);
					},
					error: function(data){

					}
				});
			};
		});
		$('#wizard_modal').data('init',true);
	},
	showNextWizard: function(tag_id) {
		$('.wizard_form').hide();
		$('#'+ tag_id).show();

		WizardComponent.checkBackButton(tag_id);
		WizardComponent.addLoadingAnimation();
		WizardComponent.checkifAnimation();
		WizardComponent.setFocus('#'+tag_id);
		WizardComponent.checkBuyToLet(tag_id);

		ga('send', {
			hitType: 'event',
			eventCategory: 'Wizard',
			eventAction: tag_id
		});
		ga('send', {
			hitType: 'pageview',
			page: WizardComponent.convertVirtualJourney(tag_id),
			title: WizardComponent.convertVirtualJourneyTitle(tag_id)
		});

		if (!lead_capture && (tag_id == 'contact' || tag_id == 'thank_you')) {
			ga('send', 'event', 'Lead Capture', 'Form', 'Journey | Lead Journey');
			lead_capture = true;
		}
	},
	convertVirtualJourneyTitle:function(tag_id) {
		routes= [];
		routes['auto_interested_in'] = 'Select the enquiry type';
		routes['type'] = 'Select the property’s usage destination';
		routes['reason'] = 'Are you buying or remortgaging?';
		routes['property_found'] = 'Have you found a property yet?';
		routes['property_type'] = 'Is the property a house or a flat?';
		routes['property_value'] = 'What is the estimated property value?';
		routes['deposit'] = 'Enter deposit amount or equity';
		routes['adverse_credit'] = 'Do you have adverse credit?';
		routes['adverse_details'] = 'Select type of adverse credit';
		routes['annual_income'] = 'Enter combined income';
		routes['animation'] = 'Congratulations, we have mortgage options for you!';
		routes['details'] ='Enter your fullname';
		routes['details_two'] = 'Enter your e-mail address';
		routes['details_three'] = 'Enter your phone number';
		routes['contact'] = 'Enter preferred-contact-time';
		routes['thank_you'] = 'Thank you!';
		return routes[tag_id];
	},
	convertVirtualJourney:function(tag_id) {
		routes= [];
		routes['auto_interested_in'] = '/virtual/journey/lead-journey/select-enquiry-type';
		routes['type'] = '/virtual/journey/lead-journey/select-property-usage-destination';
		routes['reason'] = '/virtual/journey/lead-journey/select-buying-or-remortgaging';
		routes['property_found'] = '/virtual/journey/lead-journey/found-property-yet';
		routes['property_type'] = '/virtual/journey/lead-journey/pick-if-property-is-house-or-flat';
		routes['property_value'] = '/virtual/journey/lead-journey/enter-property-value';
		routes['deposit'] = '/virtual/journey/lead-journey/enter-deposit-amount-value';
		routes['adverse_credit'] = '/virtual/journey/lead-journey/adverse-credit-status';
		routes['adverse_details'] = '/virtual/journey/lead-journey/select-type-of-adverse-credit';
		routes['annual_income'] = '/virtual/journey/lead-journey/enter-combined-income-value';
		routes['animation'] = '/virtual/journey/lead-journey/sourcing-mortgages';
		routes['details'] ='/virtual/journey/lead-journey/enter-firstname-lastname';
		routes['details_two'] = '/virtual/journey/lead-journey/enter-email-address';
		routes['details_three'] = '/virtual/journey/lead-journey/enter-phone-number';
		routes['contact'] = '/virtual/journey/lead-journey/select-preferred-contact-time';
		routes['thank_you'] = '/virtual/journey/lead-journey/journey-complete';
		return routes[tag_id];
	},
	addLoadingAnimation: function() {
		$('.circle-dot').removeClass('off');
	},
	checkifAnimation: function() {
		if($('#animation').is(':hidden')) return;
		setTimeout(function(){
			WizardComponent.removeLoadingAnimation();
		}, animationTimeOut);
	},
	foundResults: function() {
		$('#animation').find('.quote-row').addClass('hide');
		$('#animation').find('.animation-holder ').addClass('hide');
		$('#animation').find('.lg-row').removeClass('hide');
	},
	removeLoadingAnimation: function() {
		$('.circle-dot').addClass('off');
		WizardComponent.foundResults();
		setTimeout(function(){
			// $('#animation').submit();
		}, foundTimeOut);
	},
	checkBackButton: function(tag_id) {
		var start = $('#'+tag_id).find('input[type="hidden"][name="start"]');
		var route_item = $('#'+tag_id).find('input[type="hidden"][name="route_item"]');
		var wizard_id = $('#'+tag_id).find('input[type="hidden"][name="wizard_id"]');
		var $back = $('#wizard_modal').find('.back-button');

		if(start.length > 0 && route_item.val() > 1&& tag_id != "thank_you" && tag_id != "details" && tag_id != "animation") {
			$back.data('start', start.val());
			if(route_item.length > 0) {
				$back.data('item', route_item.val());
			} else {
				$back.data('item', 0);
			}
			if(wizard_id.length > 0) {
				$back.data('wizard_id', wizard_id.val());
			} else {
				$back.data('wizard_id', 0);
			}
			$back.data('previous', tag_id);

			$back.show();
		} else {
			$back.hide();
		}
	},
	checkBuyToLet: function(tag_id) {
		var start = $('#'+ tag_id).find('input[name="start"]').val();
		if(start == 'buy_to_let' || start == 'commercial_adverse_credit') {
			$('.btn-red').each(function() {
				var src = $(this).attr('src');
				var srcset = $(this).attr('srcset');
				src = src.replace('blue', 'red');
				$(this).attr('src', src);

				srcset = srcset.replace('blue', 'red');
				$(this).attr('srcset', srcset);
			});

			$('.radio_btn').each(function() {
				$(this).addClass('red');
			});
			$('.wizard-btn').each(function() {
				$(this).addClass('red');
			});
			$('input').each(function() {
				$(this).addClass('red');
			});
			$('.animation-holder').each(function() {
				$(this).addClass('red');
			});
			$('.modal-footer').addClass('red');
		} else {
			$('.btn-red').each(function() {
				var src = $(this).attr('src');
				var srcset = $(this).attr('srcset');
				src = src.replace('red', 'blue');
				$(this).attr('src', src);

				srcset = srcset.replace('red', 'blue');
				$(this).attr('srcset', srcset);
			});

			$('.radio_btn').each(function() {
				$(this).removeClass('red');
			});
			$('.wizard-btn').each(function() {
				$(this).removeClass('red');
			});
			$('input').each(function() {
				$(this).removeClass('red');
			});
			$('.animation-holder').each(function() {
				$(this).removeClass('red');
			});
			$('.modal-footer').removeClass('red');
		}
	},
	animateWizardItems: function($el) {
		setTimeout(function() {
			$('.wizard-item.btl_mortgage',$el).addClass('in');

			$('.wizard-item.remortgage-advice',$el).delay(100).queue(function(next) {
				$(this).addClass('in');
				next();
			});
			$('.wizard-item.mortgage-advice',$el).delay(150).queue(function(next) {
				$(this).addClass('in');
				next();
			});

			$('.wizard-item.mortgage-advice',$el).delay(700).queue(function(next) {
				$(this).addClass('animate-on');

				next();
			}).delay(500).queue(function(next) {
				$(this).removeClass('animate-on');
				next();
			});

			$('.wizard-item.remortgage-advice',$el).delay(1500).queue(function(next) {
				$(this).addClass('animate-on');

				next();
			}).delay(500).queue(function(next) {
				$(this).removeClass('animate-on');
				next();
			});

			$('.wizard-item.btl_mortgage',$el).delay(2200).queue(function(next) {
				$(this).addClass('animate-on');

				next();
			}).delay(500).queue(function(next) {
				$(this).removeClass('animate-on');
				next();
			});
		},100);
	},
	setBackButton: function() {
		var $back = $('#wizard_modal .back-button');
		$back.hide();

		$back.on('click',function(e){
			e.preventDefault(e);
			var route_item = new Number($(this).data('item'));
			var wizard_id = $(this).data('wizard_id');
			var previous = $(this).data('previous');
			$.post({
				url:$(this).data('url'),
				data:'_token=' + $('meta[name="_token"]').attr('content') +'&start=' + $(this).data('start') + '&route_item=' + route_item + '&wizard_id=' + wizard_id,
				dataType: 'json',
				success: function(data){
					if (data.wizard_id != "0") {
						WizardComponent.addHidden(data.route, 'wizard_id', data.wizard_id);
					}
					WizardComponent.showNextWizard(data.route, previous);

				},
				error: function(data){

				}
			})
		});
	},
	setWizardItemBtns: function() {

		remortgage_advice_item.click(function(e) {
			e.preventDefault();
			WizardComponent.animateWizardItems($('#wizard_modal'));
			$('#wizard_modal').modal('show');
			if ($(window).width() < 768) {
				scrollTo();
			}
			// WizardComponent.checkForWizardId('required-handsets');
			WizardComponent.addHidden('type', 'start', 'remortgage');
			WizardComponent.addHidden('type', 'route_item', 1);
			WizardComponent.showNextWizard('type');
		})

		mortgage_wizard_item.click(function(e) {
			e.preventDefault();
			WizardComponent.animateWizardItems($('#wizard_modal'));
			$('#wizard_modal').modal('show');
			if ($(window).width() < 768) {
				scrollTo();
			}
			// WizardComponent.checkForWizardId('line_types');
			WizardComponent.addHidden('type', 'start', 'residential');
			WizardComponent.addHidden('type', 'route_item', 1);
			WizardComponent.showNextWizard('type');
		});

		cta_mortgage_advice_item.click(function(e) {
			e.preventDefault();
			WizardComponent.animateWizardItems($('#wizard_modal'));
			$('#wizard_modal').modal('show');
			if ($(window).width() < 768) {
				scrollTo();
			}
			// WizardComponent.checkForWizardId('line_types');
			WizardComponent.addHidden('type', 'start', 'residential');
			WizardComponent.addHidden('type', 'route_item', 1);
			WizardComponent.showNextWizard('type');
		});

		btl_mortgage_wizard_item.click(function(e) {
			e.preventDefault();
			WizardComponent.animateWizardItems($('#wizard_modal'));
			$('#wizard_modal').modal('show');
			if ($(window).width() < 768) {
				scrollTo();
			}
			// WizardComponent.checkForWizardId('required-handsets')
			WizardComponent.addHidden('type', 'start', 'buy_to_let');
			WizardComponent.addHidden('type', 'route_item', 1);
			WizardComponent.showNextWizard('type');
		});

	},
	toggleAnimations: function() {
		$('.wizard-item.mortgage-advice').toggleClass('off');
		$('.wizard-item.mortgage-advice .heading').toggleClass('off');
		$('.wizard-item.mortgage-advice .icon-holder .mortgage-advice-colour').toggleClass('off');
		$('.wizard-item.mortgage-advice .mortgage-advice-btn').toggleClass('off');
		$('.wizard-item.mortgage-advice .btn span').toggleClass('off');

		$('.wizard-item.btl_mortgage').toggleClass('off');
		$('.wizard-item.btl_mortgage .heading').toggleClass('off');
		$('.wizard-item.btl_mortgage .icon-holder .btl_mortgage-colour').toggleClass('off');
		$('.wizard-item.btl_mortgage .btl_mortgage-btn').toggleClass('off');
		$('.wizard-item.btl_mortgage .btn span').toggleClass('off');

		$('.wizard-item.remortgage-advice').toggleClass('off');
		$('.wizard-item.remortgage-advice .heading').toggleClass('off');
		$('.wizard-item.remortgage-advice .icon-holder .remortgage-advice-colour').toggleClass('off');
		$('.wizard-item.remortgage-advice .remortgage-advice-btn').toggleClass('off');
		$('.wizard-item.remortgage-advice .btn span').toggleClass('off');
	},
	checkForWizardId: function() {
		var wizard_id = $('#interested_in').find('input[type="hidden"][name="wizard_id"]');
		if(wizard_id.length > 0) {
			WizardComponent.addHidden(tag_id, 'wizard_id',wizard_id.val());
		}
	},
	enableRadioNext: function() {
		$('.active-radio').on('change', function (e) {

			$('.active-radio').each(function(){
				if($(this).is(':checked')) {
					var $$ = $(this).closest('.wizard_form');
					$$.find('.wizard-btn').removeClass('disabled').prop('disabled', false);
					$$.find('.wizard-btn .active-btn').removeClass('hide');
					$$.find('.wizard-btn .hover-btn').removeClass('hide');
					$$.find('.wizard-btn .inactive-btn').addClass('hide');
					$$.find('label').removeClass('inactive');
				}
			});
		});
	},
	setRadio: function(tag_id) {
		$('#'+tag_id).find('input[type="radio"]').on('change', function(e){
			$('input[name="'+ $(this).attr('name')+'"]').each(function(e){

				if ($(this).is(':checked')) {
					$(this).parent('label').addClass('active');
				} else {
					$(this).parent('label').removeClass('active');
				}
			});
		});
	},
	setCheckBoxes: function(tag_id) {
		$('#'+tag_id).find('input[type="checkbox"]').on('change', function(e){
			if ($(this).is(':checked')) {
				$(this).parent('label').addClass('active');
			} else {
				$(this).parent('label').removeClass('active');
			}
		});
	},
	resetPropertyValue: function() {
		$('input[name="property_value"]').val('');
	},
	removeAllHidden: function() {
		$('#wizard_modal').find('input[type="hidden"][name="start"]').remove();
		$('#wizard_modal').find('input[type="hidden"][name="route_item"]').remove();
		$('#wizard_modal').find('input[type="hidden"][name="wizard_id"]').remove();
	},
	addHidden: function(tag_id, name, value) {
		var current = $('#'+tag_id).find('input[type="hidden"][name="'+ name +'"]');
		if (current.length == 0 ) {
			var $base_in = $("<input />", {
				"name": name,
				"type": 'hidden',
				"value": value,
				"class": "form-control",
			});
			$('#'+tag_id).append($base_in);
		} else {
			current.val(value);
		}
	}

};

$(function() {
	WizardComponent.init();
});
