$(document).ready(function() {
	var branch_map;
	var marker_icon =  '/media/images/map_marker@2x.png';
	if (typeof google == 'undefined') return;
	var bounds = new google.maps.LatLngBounds();

	initializeMap();
	initializeAllMap();

	function initializeMap() {
		if($('#branch-map').length == 0) return;
		var branch_lat = $('#branch-map').data('lat');
		var branch_lon = $('#branch-map').data('lon');

		branch_map = new google.maps.Map(document.getElementById('branch-map'), {
			zoom: 12,
			center: new google.maps.LatLng(branch_lat,branch_lon),
			mapTypeId: 'roadmap',
			animation: google.maps.Animation.DROP,
			scrollwheel:  false,
		});

		addMarker({
			position: new google.maps.LatLng(branch_lat,branch_lon),
			type: 'info',
			animation: google.maps.Animation.DROP,
			title: $('#branch-map').data('title'),
			tel: $('#branch-map').data('tel'),
			address: $('#branch-map').data('address')
		}, branch_map);
	};

	function initializeAllMap() {
		if($('#branch-map-all').length == 0) return;


		all_branch_map = new google.maps.Map(document.getElementById('branch-map-all'), {
			zoom: 8,
			mapTypeId: 'roadmap',
			animation: google.maps.Animation.DROP,
			scrollwheel:  false,
		});

		var markers = $('#branch-map-all').data('url');

		$.ajax(markers)
		.done(function(data) {
			$.each(data, function(itm, marker){
				if (marker.lat == "0.00000000" || marker.lon == "0.00000000") {
					return false;
				}

				addMarker({
					position: new google.maps.LatLng(marker.lat,marker.lon),
					type: 'info',
					animation: google.maps.Animation.DROP,
					title: marker.title,
					tel: marker.tel,
					address: marker.address
				}, all_branch_map);
			});
			all_branch_map.fitBounds(bounds);
		});
	};

	function addMarker(feature, map_name) {
		var marker = new google.maps.Marker({
			position: feature.position,
			animation: google.maps.Animation.DROP,
			icon: new google.maps.MarkerImage(icon, null, null, null, new google.maps.Size(36,52)),
			map: map_name,
			title:feature.title
		});
		var contentString = '<div class="map-content" id="content">'+
		'<div id="siteNotice"></div>'+
		'<h5 id="firstHeading" class="firstHeading">'+ feature.title +'</h5>'+
		'<div id="bodyContent">'+
		'<div class="row">' +
		'<div class="col-xs-12">'+ feature.address +
		'</div>'+
		'<div class="col-xs-12"><i>'+ feature.tel +
		'</i></div>'+
		'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		bounds.extend(marker.position);
	}
});


