;
var latest_rates = {
	init: function() {
		if ($('.latest_mortgage_rates').length == 0) return;
		var $$ = $('.latest_mortgage_rates');
		this.setButtons($$);
		this.load($$);
	},
	load: function($el) {
		this.setData($el.data('url'), 'first_time_buyer');
	},
	setButtons: function($el) {
		var controls = $el.find('.controls');
		controls.on('click.btn', '[data-mortage_type]', function(e) {
			controls.find('a').removeClass('active');
			var mortgage_type = $(this).data('mortage_type');
			$(this).addClass('active');
			latest_rates.setData($el.data('url'), mortgage_type);
		})

	},
	setData: function(durl, mortgage_type) {
		$.get({
			url: durl,
			data: 'mortgage_type=' + mortgage_type,
			dataType: 'html',
		}, function(data) {
			$('.table').replaceWith(data);
		});
	}
};
$(function () {
	latest_rates.init();
});
