;
$(function() {
	if($('.odometer').length == 0) return;

	var box_offset = $('.odometer').closest('.page_row').position().top - 1000;

	var animation_run = false;
	var scroll = 0;
	var checkScrollHeight = function(){
		if (window.scrollY !== scroll) {
			scroll = window.scrollY;
			if (!animation_run && window.scrollY > box_offset) {
				animation_run = true;
				start_odometer();
			}
		}

		window.requestAnimationFrame(checkScrollHeight);
	}


	window.requestAnimationFrame(checkScrollHeight);
});


function start_odometer() {
	if($('.odometer').length == 0) return;
	$('.odometer').each(function(i,itm){
		console.log('start');
		var $name = $('#'+itm.id);
		var update = new Number($name.data('time'));
		var start_number = new Number($name.data('start'));
		var count = 0;

		$name.text(new Number(count + start_number).toFixed(0));

		setInterval(function(){
			count += (update*5);
			$name.text(new Number(count + start_number).toFixed(0));
		}, 5000);
	})
}
