Number.prototype.numberFormat = function(decimals, dec_point, thousands_sep) {
	dec_point = typeof dec_point !== 'undefined' ? dec_point : '.';
	thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : ',';

	var parts = this.toFixed(decimals).split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

	return parts.join(dec_point);
}

var calculator = new function() {
	this.total_gross_income = 0;
	this.deposit_amount = 0;
	this.salary_multiplier = 4.5;

	this.number_of_months = 300;

	this.interest_rate = function () {
		var ltv = this.ltv(),
		rate;

		if (ltv >= 0.95) {
			rate = 4;
		} else if (ltv >= 0.90) {
			rate = 2.99;
		} else if (ltv >= 0.85) {
			rate = 1.99;
		} else if (ltv >= 0.80) {
			rate = 1.6;
		} else if (ltv >= 0.75) {
			rate = 1.5;
		} else if (ltv >= 0.70) {
			rate = 1.5;
		} else {
			rate = 1.24;
		}
		return rate/100;
	};

	this.monthly_interest_rate = function () {
		return this.interest_rate() / 12;
	};

	this.mortgage_value = function() {
		return (this.salary_multiplier * this.total_gross_income);
	};

	this.maximum_house_price = function() {
		return this.mortgage_value() + this.deposit_amount;
	};

	this.ltv = function() {
		if (this.maximum_house_price() <= 0) {
			return '-';
		}
		return this.mortgage_value() / this.maximum_house_price();
	};

	this.monthly_payments = function() {
		return this.mortgage_value() * ((this.monthly_interest_rate() * Math.pow((1 + this.monthly_interest_rate()), this.number_of_months)) / (Math.pow((1 + this.monthly_interest_rate()), this.number_of_months) - 1));
	};

	this.stamp_duty = function(first_time_buyer) {
		var total_stamp_duty = 0;
		var remaining_money = this.maximum_house_price();

		if (typeof first_time_buyer !== undefined && first_time_buyer) {
			remaining_money -= 300000;
		}

		var data = [
		{'limit': 1500000, 'rate':0.12},
		{'limit': 925001, 'rate':0.10},
		{'limit': 250001, 'rate':0.05},
		{'limit': 125001, 'rate':0.02},
		{'limit': 40001, 'rate':0}
		];

		for (index = 0; index < data.length; ++index) {
			if (remaining_money >= data[index].limit) {
				var thisChunk = remaining_money - data[index].limit;
				total_stamp_duty +=data[index].rate * thisChunk;

				remaining_money -= thisChunk;
			}
		}

		return Math.round( total_stamp_duty );
	};

	this.second_property_stamp_duty = function() {
		return Math.round( this.stamp_duty() + (0.03 * this.maximum_house_price()) );
	};

	this.first_time_stamp_duty = function() {
		if (this.maximum_house_price() > 500000) {
			return this.stamp_duty();
		}
		if (this.maximum_house_price() <= 300000) {
			return 0;
		}
		return Math.round( (this.maximum_house_price() - 300000) * 0.05 );
	};
};

var $total_gross_income,
$deposit_amount,
$salary_multiplier,
$mortgage_value,
$maximum_house_price,
$stamp_duty_estimate,
$second_property_stamp_duty,
$first_time_buyer_stamp_duty_estimate,
$mortgage_calculator_warnings,
$declaration,
$calculate,
$first_name,
$last_name,
$your_email,
$your_phone_number;

var calculate_has_been_clicked = false;
var data_has_been_sent = false;

function renderCalculatorOutputs(durl) {
	if (!calculate_has_been_clicked) {
		return false;
	}
	if (!data_has_been_sent) {
		var data = {};
		if ($first_name.val().trim() == '') {
			$first_name.closest('div').find('.error').text('Please enter your first name');
			calculate_has_been_clicked = false;
			$first_name.trigger('focus');
			return false;
		} else {
			data.first_name = $first_name.val().trim();
		}

		if ($last_name.val().trim() == '') {
			$last_name.closest('div').find('.error').text('Please enter your last name');
			calculate_has_been_clicked = false;
			$last_name.trigger('focus');
			return false;
		} else {
			data.last_name = $last_name.val().trim();
		}

		if ($your_email.val().trim() == '') {
			$your_email.closest('div').find('.error').text('Please enter your email');
			calculate_has_been_clicked = false;
			$your_email.trigger('focus');
			return false;
		} else {
			data.your_email = $your_email.val().trim();
		}

		if ($your_phone_number.val().trim() == '') {
			$your_phone_number.closest('div').find('.error').text('Please enter your phone number');
			calculate_has_been_clicked = false;
			$your_phone_number.trigger('focus');
			return false;
		} else {
			data.your_phone_number = $your_phone_number.val().trim();
		}
		data.total_gross_income = $total_gross_income.val().trim();
		data.deposit_amount = $deposit_amount.val().trim();
		// data.call_me_back = $("#call_me_back").prop('checked') ? 'Yes' : 'No';
		data.source = $("#source").val() || '';
		data._token = $('meta[name="_token"]').attr('content') ;

		$.post(durl, data, function(d){
			if (typeof d.error !== 'undefined') {
				alert(d.error);
				return false;
			}
			if (d.success) {
				ga('send', 'event', 'Lead Capture', 'Form', 'Mortgage Calculator');
			}
			data_has_been_sent = true;
		});

	}

	if (!$declaration.prop('checked')) {
		$mortgage_value.text('-');
		$maximum_house_price.text('-');
		$stamp_duty_estimate.text('-');
		$second_property_stamp_duty_estimate.text('-');
		$first_time_buyer_stamp_duty_estimate.text('-');

		$declaration.closest('div').find('.error').text('You must read and agree to our declaration - please tick the box');
		$declaration.trigger('focus');
		calculate_has_been_clicked = false;

		return false;
	}
	if ($total_gross_income.val() == 0 || $deposit_amount.val() == 0) {
		$mortgage_value.text('-');
		$maximum_house_price.text('-');
		$stamp_duty_estimate.text('-');
		$second_property_stamp_duty_estimate.text('-');
		$first_time_buyer_stamp_duty_estimate.text('-');

		return false;
	}
	$mortgage_value.text( '£' + Math.round( calculator.mortgage_value(), 0 ).numberFormat(0) );
	$maximum_house_price.text( '£' + Math.round( calculator.maximum_house_price(), 0 ).numberFormat(0) );
	$stamp_duty_estimate.text( '£' + calculator.stamp_duty().numberFormat(0) );
	$second_property_stamp_duty_estimate.text( '£' + calculator.second_property_stamp_duty().numberFormat(0) );
	$first_time_buyer_stamp_duty_estimate.text( '£' + calculator.first_time_stamp_duty().numberFormat(0) );

	var count_warnings = 0;

	if (calculator.salary_multiplier > 4.5) {
		count_warnings++;
		$("#high_multiplier").show();
	} else {
		$("#high_multiplier").hide();
	}

	if (calculator.ltv() != '-' && calculator.ltv() >= 0.95) {
		count_warnings++;
		$("#low_deposit").show();
	} else {
		$("#low_deposit").hide();
	}

	if (count_warnings > 0) {
		$mortgage_calculator_warnings.fadeIn();
	} else {
		$mortgage_calculator_warnings.fadeOut();
	}
	scrollTo($('#mortgage_calculator'));
}

$(document).ready(function() {

	$total_gross_income = $("#total_gross_income");
	$deposit_amount = $("#deposit_amount");
	$salary_multiplier = $("#salary_multiplier");
	$mortgage_value = $("#mortgage_value");
	$maximum_house_price = $("#maximum_house_price");
	$stamp_duty_estimate = $("#stamp_duty_estimate");
	$second_property_stamp_duty_estimate = $("#second_property_stamp_duty_estimate");
	$first_time_buyer_stamp_duty_estimate = $("#first_time_buyer_stamp_duty_estimate");
	$mortgage_calculator_warnings = $("#mortgage_calculator_warnings");
	$declaration = $("#declaration");
	$calculate = $("#calculate");
	// $call_me_back = $("#call_me_back");

	$first_name = $("#first_name");
	$last_name = $("#last_name");
	$your_email = $("#your_email");
	$your_phone_number = $("#your_phone_number");


	$total_gross_income.on('input', function(){
		if ($(this).val() == '' || parseFloat($(this).val()) <= 0) {
			calculator.total_gross_income = 0;
		} else {
			calculator.total_gross_income = parseFloat($(this).val());
		}
		renderCalculatorOutputs();
	});

	$deposit_amount.on('input', function(){
		if ($(this).val() == '' || parseFloat($(this).val()) <= 0) {
			calculator.deposit_amount = 0;
		} else {
			calculator.deposit_amount = parseFloat($(this).val());
		}
		renderCalculatorOutputs();
	});

	$salary_multiplier.on('input change', function(){
		if ($(this).val() == '' || parseFloat($(this).val()) <= 0) {
			calculator.salary_multiplier = 0;
		} else {
			calculator.salary_multiplier = parseFloat($(this).val());
		}
		renderCalculatorOutputs();
	});

	$calculate.on('click', function(e){
		e.preventDefault();
		$('.error').text(' ');
		calculate_has_been_clicked = true;
		var durl = $(this).data('url');
		renderCalculatorOutputs(durl);

		// Open CheckMyFile modal...
		setTimeout(function() {
      $('#checkmyfile_modal').modal('show');
    }, 2000);

	});

});
