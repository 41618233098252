var enquiry_wizard = {
	init: function() {
		this.setWizardSectionSelect();
		this.checkSection();
		this.setSubmitButton();
	},
	checkSection: function() {
		var pass_section;
		$('.required').on('keyup', function (e) {
			pass_section = 0;
			$(".required").each(function (i, item) {
				if ($(item).val() != '') {
					pass_section +=  1;
				}
			})
			if(pass_section == $(".required").length) {
				enquiry_wizard.setSubmitButtonState(false);
			} else {
				enquiry_wizard.setSubmitButtonState(true);
			}
		});
	},
	setSubmitButton: function() {
		if ($('#full_contact_form').length > 0) return;

		$('.submit-button').on('click', function(e) {
			e.preventDefault();
			var durl = $(this).data('url');
			var buyer_type_id = $('select[name="buyer_type_id"]').val();
			var approx_borrow_value = $('input[name="approx_borrow_value"]').val();
			var approx_property_value = $('input[name="approx_property_value"]').val();
			$.get({
				url: durl,
				data: 'approx_borrow_value=' + approx_borrow_value + '&approx_property_value=' + approx_property_value + '&buyer_type_id=' + buyer_type_id,
				dataType: 'json',
				success: function(data){
					enquiry_wizard.hideWizardSection();
					$('.result').find('.title').html('<h2>'+ data.title + '</h2>');
					$('.result').find('.subtitle').html('<p>'+data.subtitle+data.text+'</p>');
					scrollTo($('#enquiry-wizard'));
					var focusTimeout2 = setTimeout(function() {
					$('	#first_name').focus();
				},500);
				},
				error: function(data){

				}
			});
		});
	},
	hideWizardSection: function() {
		$('.enquiry-wizard-section').addClass('wizard-hide');
		$('.wizard-section').removeClass('active');
		$('.title-row').addClass('hide');
		$('.contact_form').addClass('active');
	},
	setSubmitButtonState: function(state) {
		var  $$  = $('.submit-button');
		$$.prop('disabled', state);
		if (state) {
			$$.addClass('disabled');
		} else {
			$$.removeClass('disabled');
		}
	},
	setWizardSectionSelect: function() {
		if ($('.wizard-section-select').length == 0) return;

		$('.wizard-section-select').on('change', function (e) {
			var buyer_type = $(this).val();
			if(buyer_type > 0) {
				scrollTo($('#enquiry-wizard'));
				$('.wizard-section').addClass('active');
				$(this).removeClass('active');
				var focusTimeout = setTimeout(function() {
					$('	#approx_property_value').focus();
				},500);
			} else {
				$('.wizard-section').removeClass('active');
				$(this).addClass('active');
			}
		});
	},
};

$(function () {
	enquiry_wizard.init();
});
